export function TriangleInCircleExclamationIcon(
  props: React.HTMLAttributes<SVGElement> & { fill?: `fill-${string}` },
) {
  var { fill, className, ...svgProps } = props;

  const fillClassName = fill ?? 'fill-neutral-100';

  return (
    <svg
      className={className}
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect width="72" height="72" rx="36" fill="black" />
        <path
          className={fillClassName}
          d="M36.0001 16.4999L16.5001 49.4999H55.5001L36.0001 16.4999ZM37.8001 44.9999H34.0501V41.2499H37.8001V44.9999ZM34.2001 37.4999V26.9999H37.9501V37.4999H34.2001Z"
        />
      </g>
    </svg>
  );
}
