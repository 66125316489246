'use client';

import { Todo } from 'global';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { TriangleInCircleExclamationIcon } from '@/components/icons/triangle-in-circle-exclamation-icon';
import Tenants from '@/components/tenants';
import { Button } from '@/design-system-components/button/button';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { cn } from '@/utils/tailwind';

import { Icon } from './icon';
import { RcLink } from './rc-link';

interface FallbackRscProps {
  severity?: Todo;
  componentStack?: string;
  className?: string;
  children?: React.ReactNode;
  heading?: string;
  description?: string;
}

export function GenericErrorBoundary({
  severity,
  componentStack,
  error,
  heading,
  description,
  ...props
}: FallbackProps & FallbackRscProps) {
  const { sentryLog } = useSentryMonitoring();

  useEffect(() => {
    switch (severity) {
      case 'critical': {
        sentryLog.fatal(error);
        break;
      }
      case 'warning': {
        sentryLog.warning(error);
        break;
      }
      case 'info': {
        sentryLog.info(error);
        break;
      }
      default: {
        sentryLog.error(error);
        break;
      }
    }
  }, [componentStack, error, sentryLog, severity]);

  const t = useTranslations('genericErrorBoundary');

  const underMaintenanceText = t('underMaintenance');
  const checkBackLaterText = t('checkBackLater');
  const somethingWentWrongText = t('somethingWentWrong');
  const descriptionText = t.rich('description_link', {
    link: (chunks) => <RcLink href="/help-center">{chunks}</RcLink>,
  });
  const refreshText = t('refresh');

  const onRefresh = (): void => {
    window.location.reload();
  };

  return (
    <Tenants>
      <Tenants.IsRobinhood>
        <div
          className={cn('px-4 container-responsive lg:px-0', props.className)}
        >
          {props.children ?? (
            <div className="flex flex-col items-center">
              <TriangleInCircleExclamationIcon className="mb-4" />

              <h2 className="mb-4 text-center text-heading-3">
                {underMaintenanceText}
              </h2>

              <p className="mb-4 text-center">{checkBackLaterText}</p>
            </div>
          )}
        </div>
      </Tenants.IsRobinhood>

      <Tenants.IsGenericTenant>
        <div
          className={cn('px-4 container-responsive lg:px-0', props.className)}
        >
          {props.children ?? (
            <div className="flex flex-col items-center rounded-lg bg-white px-4 py-8 shadow-md">
              <Icon name="circle-exclamation" className="mb-6 h-10 w-10" />

              <h2 className="mb-6 text-center text-heading-3 lg:text-heading-2">
                {heading ?? somethingWentWrongText}
              </h2>

              <p className="mb-4 mb-6 text-center lg:max-w-lg">
                {description ?? descriptionText}
              </p>

              <Button variant="primary" onPress={onRefresh}>
                {refreshText}
              </Button>
            </div>
          )}
        </div>
      </Tenants.IsGenericTenant>
    </Tenants>
  );
}

/**
 * Usage:
 * ** using inside a component
 * note: ErrorBoundary is from `react-error-boundary` lib
 * note: {log} is from `@/hooks/monitoring/use-monitoring`
 * <ErrorBoundary FallbackComponent={GenericErrorFallbackUi} onError={log.critical}>
 *  <Component />
 * </ErrorBoundary>
 *
 * ** using as main layout for a React Server component error
 * note: componentStack is the location or identifier of the error component
 * note: `critical` is the severity of the error, we can use `warning`, `info`, and `error` as well
 * <GenericErrorFallbackUi error={error} componentStack={componentStack} severity="critical">Custom content</GenericErrorFallbackUi>
 */
