'use client';
// TODO: will be implemented in https://kaligo.atlassian.net/browse/RCF-575

import { GenericErrorBoundary } from '@/components/generic-error-boundary';

export interface ErrorProps {
  error: Error;
  reset: () => void;
}

export default function RootError(props: ErrorProps) {
  const { reset, error } = props;
  return (
    <GenericErrorBoundary
      error={error}
      resetErrorBoundary={reset}
      componentStack={error.stack ?? 'Homepage'}
      severity="critical"
      className="flex h-full flex-col [&>div]:my-auto"
    />
  );
}
