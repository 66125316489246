import useIsRobinhood from '@/hooks/config/tenants/use-is-robinhood';
import { Children, isValidElement } from 'react';
import TenantIsGenericTenant from './tenant-is-generic-tenant';
import TenantIsRobinhood from './tenant-is-robinhood';

/***
 * @deprecated this is a temporary solution, do not use this hook in new code. Please add new config to UI settings instead
 *
 * Tenants is a decision tree component to render the right tenant component block
 *
 * To add a new tenant,
 *  - Create a new "TenantIsYourClientName" component (to render children with
 *    any special logic)
 *  - Add the matching condition below to ensure it is rendered in the right
 *    tenant conditions
 *  - Ensure that it is assigned to "Tenants.IsYourClientName" to be exported
 *    so it can be used
 *
 * Example of use:
 *
 *  <Tenants>
 *    <!-- Children in the following block will render if current tenant is Robinhood -->
 *    <Tenants.IsRobinhood>Hi Robinhood users<Tenants.IsRobinhood/>
 *
 *    <!-- Children in the following block will render for everyone else -->
 *    <Tenants.IsGenericTenant>Hi Ascenda users<Tenants.IsGenericTenant/>
 *
 *    <!-- Else nothing renders -->
 *  </Tenants>
 *
 ***/
const Tenants = ({ children }: { children: React.ReactNode }) => {
  const isRobinhood = useIsRobinhood();
  let result: React.ReactNode = null;
  let fallback: React.ReactNode = null;

  // Here, we loop through all children of Tenants, and only render the ones
  // that match the tenantId with the right component
  Children.forEach(children, (child) => {
    if (!isValidElement(child)) {
      return;
    }

    // Add your tenant matching conditions here
    if (isRobinhood && child.type === Tenants.IsRobinhood) {
      result = child;
    }

    // Fallback instance
    if (child.type === Tenants.IsGenericTenant) {
      fallback = child;
    }
  });

  return <>{result || fallback}</>;
};
Tenants.displayName = 'Tenants';

// Export your tenants by adding them here
Tenants.IsGenericTenant = TenantIsGenericTenant;
Tenants.IsRobinhood = TenantIsRobinhood;

export default Tenants;
